import {
  computed, ref, Ref, unref,
} from 'vue';
import { useDi } from '@/plugins/di';
import * as LxpAssignmentService from '@/services/api/lxp-assignment';
import { IAssignment } from '@/domains/assignment';
import { Assignment } from '@/domains/assignment/Assignment';
import { AtomType } from '@/domains/atom';
import { PlayerSessionStatus } from '@/domains/common';
import { TrackSessionStatus } from '@/domains/track';
import { IAssignRequest, IRecommendation, IUnassignRequest } from '@/services/api/lxp-assignment/types';
import { useI18n } from '@/plugins/vue-i18n';

interface IUseScormPlayerProps {
  playerSessionId: Ref<number>;
  atomType: Ref<AtomType> | AtomType;
  isMandatoryRecommendation?: Ref<boolean>;
}

export function useAssignment({ playerSessionId, atomType, isMandatoryRecommendation }: IUseScormPlayerProps) {
  const di = useDi();
  const { t } = useI18n();

  /* State */
  const isLoading = ref(false);
  const assignment = ref<IAssignment>(new Assignment({}));
  const recommendations = ref<IRecommendation[]>([]);

  /* Getters */
  const catalogueAtomVersionId = computed(() => assignment.value.catalogueAtomVersionId);
  const assignmentUserId = computed(() => assignment.value.assignmentUserId);
  const assignmentDeadline = computed(() => assignment.value.finishDate);

  /* Flags */
  const isSelfAssignment = computed(() => assignment.value.selfAssigned);
  const hasDeadline = computed(() => assignmentDeadline.value !== null);

  /* Methods */
  const getDeadlineVisibility = (status?: PlayerSessionStatus | TrackSessionStatus) =>
    hasDeadline && ![PlayerSessionStatus.COMPLETED, TrackSessionStatus.COMPLETED].includes(
      status ?? PlayerSessionStatus.UNKNOWN,
    );

  const fetchAssignmentBySession = async () => {
    try {
      isLoading.value = true;

      const response = await LxpAssignmentService.assignmentBySessionGet({
        params: { atomType: unref(atomType) },
        paths: { playerSessionId: playerSessionId.value },
      });

      assignment.value = response;
    } catch (e: any) {
      console.error(e);

      di.notify.error({ content: e.message });
    } finally {
      isLoading.value = false;
    }
  };

  const onAssignHandler = async (payload: IAssignRequest) => {
    try {
      return LxpAssignmentService.assign(payload);
    } catch (e: any) {
      console.error(e);
      di.notify.error({ content: t('useAssignment.errors.assign') });
      throw e;
    }
  };

  const onUnassignHandler = async (payload: IUnassignRequest) => {
    try {
      await LxpAssignmentService.unassign(payload);
    } catch (e: any) {
      console.error(e);
      di.notify.error({ content: t('useAssignment.errors.unassign') });
      throw e;
    }
  };

  const onAssignmentRunHandler = async () => {
    try {
      await fetchAssignmentBySession();

      const response = await LxpAssignmentService.assignmentRun({
        paths: { assignmentUserId: assignment.value.assignmentUserId },
        data: {
          catalogueAtomMainId: assignment.value.catalogueAtomMainId,
          forceNewVersion: true,
        },
      });

      return response.playerSessionId;
    } catch (e) {
      console.error(e);
      di.notify.error({ content: t('useAssignment.errors.run') });
      return null;
    }
  };

  const getRecommendations = async () => {
    try {
      const { recommendations: data } = isMandatoryRecommendation?.value
        ? await LxpAssignmentService.mandatoryRecommendationsGet({
          playerSessionId: playerSessionId.value,
        })
        : await LxpAssignmentService.freeRecommendationsGet({
          playerSessionId: playerSessionId.value,
        });

      recommendations.value = data;
    } catch (err: any) {
      console.error(err);
      di.notify.error({ content: t('useAssignment.errors.recommendationsGet') });
    }
  };

  return {
    assignment,
    isLoading,
    isSelfAssignment,
    catalogueAtomVersionId,
    assignmentDeadline,
    hasDeadline,
    recommendations,
    assignmentUserId,
    getDeadlineVisibility,
    fetchAssignmentBySession,
    onAssignHandler,
    onUnassignHandler,
    onAssignmentRunHandler,
    getRecommendations,
  };
}
