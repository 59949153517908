
import {
  ref, computed, defineComponent, onMounted, toRefs,
} from 'vue';
import { IBreadcrumb } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';

import { useAssignment } from '@/domains/assignment/composables';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { AtomType } from '@/domains/atom';
import { useQuizSession } from '@/domains/quiz/composables/useQuizSession';
import { useQuizDetailedResult } from '@/domains/quiz/composables/useQuizDetailedResult';
import QuizAnswersQuestion from '@/components/quiz/QuizAnswer/QuizAnswersQuestion/QuizAnswersQuestion.vue';
import QuizAnswersProgressPlate from '@/components/quiz/QuizAnswer/QuizAnswersProgressPlate/QuizAnswersProgressPlate.vue';
import { BasePage } from '@/components/ui/BasePage';

export default defineComponent({
  name: 'AssignmentQuizAnswers',

  components: {
    QuizAnswersQuestion,
    QuizAnswersProgressPlate,
    BasePage,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const { t } = useI18n();

    const isLoading = ref<boolean>(true);

    const {
      isLoading: isAssignmentLoading,
      fetchAssignmentBySession: fetchAssignment,
      assignment,
      isSelfAssignment,
      catalogueAtomVersionId,
      onUnassignHandler,
    } = useAssignment({ playerSessionId, atomType: AtomType.QUIZ });

    const {
      fetchAtom,
      atom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const {
      session: quiz,
      fetchSession,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
    } = useQuizSession({ playerSessionId });

    const {
      questions,
      correctAnswers,
      questionsCount,
      testThreshold,
      answersDisplayType,
      fetchQuizResults,
    } = useQuizDetailedResult({ quiz, playerSessionId });

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    onMounted(async () => {
      Promise.all([
        fetchSession(),
        fetchAssignment()
          .then(fetchAtom),
        fetchQuizResults(),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return {
      isLoading,
      quiz,
      atom,
      assignment,
      isAssignmentLoading,
      isSelfAssignment,
      isSessionStarted,
      isSessionCompleted,
      isSessionActive,
      breadcrumbs,
      onUnassignHandler,
      questions,
      correctAnswers,
      questionsCount,
      testThreshold,
      answersDisplayType,
    };
  },
});
