import { AtomType, IAtom } from '../atom';
import { UUID } from '../common';
import { IAssignment } from '@/domains/assignment';

export type TrackSessionId = number;

export enum TrackSessionStatus {
  COMPLETED = 'completed',
  ACTIVE = 'active',
  NEW = 'new',
}

export enum ITrackType {
  SECTION = 'section',
  STEP = 'step',
  TRACK = 'track',
}

export interface BaseStep {
  id: UUID; // ID шага
  atomContentId: UUID; // UUID контента в Атоме
  atomType: AtomType; // Сервис к которому относится шаг (multi_level_test или multi_content) - atom_name из Каталога
  catalogueAtomId: UUID; // UUID атома в Каталоге
  name: string; // Название шага
  order: number; // Порядковый номер шага
  playerSessionId?: UUID | null; // ID сессии из сервиса multi_level_test или multi_content
  // NOTE: мб понадобится отдельный enum
  stepSessionStatus?: TrackSessionStatus | null; // Статус сессии шага active, completed
  isCompleted?: boolean;
}

export interface Step extends BaseStep {
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  isPreview?: boolean;
}

export interface IAssignedTrack
  extends Pick<
    IAssignment,
    | 'catalogueAtomMainId'
    | 'catalogueAtomVersionId'
    | 'assignmentUserId'
    | 'name'
    | 'finishDate'
    | 'trackProgress'
    | 'playerSessionId'
    | 'onRecommendation'
    | 'atomType'
  > {}

export interface IWillStudyTrack
  extends Pick<
    IAssignment,
    | 'catalogueAtomMainId'
    | 'catalogueAtomVersionId'
    | 'assignmentUserId'
    | 'name'
    | 'trackProgress'
    | 'playerSessionId'
    | 'atomType'
  > {}

export interface IContinueTrack
  extends Pick<IAssignment,
  | 'catalogueAtomMainId'
  | 'catalogueAtomVersionId'
  | 'assignmentUserId'
  | 'name'
  | 'trackProgress'
  | 'playerSessionId'
  | 'atomType'
  > {}

interface IAtomAssignment {
  id: number;
  assignmentUserId: number;
  selfAssigned: boolean;
}

export interface IAtomWithAssigments extends IAtom {
  assignments?: IAtomAssignment[];
}

export interface IRecommendationCardClickPayload {
  assignmentUserId: IAssignment['assignmentUserId'];
  catalogueAtomMainId: IAssignment['catalogueAtomMainId'];
  playerSessionId: IAssignment['playerSessionId'];
  atomType: AtomType;
}
