import { DateTimeISO8601 } from '@/domains/common';
import { AtomSource, AtomType } from '@/domains/atom';
import { AssignmentStatus, IAssignment } from './types';

export class Assignment implements IAssignment {
  catalogueAtomMainId: UUID; // ID оригинального атома из сервиса catalogue

  assignmentMainId: number; // ID Главного назначения пользователя

  startDate: DateTimeISO8601; // Дата начала

  finishDate: DateTimeISO8601 | null; // Дата окончания

  selfAssigned: boolean; // Признак самоназначения

  onRecommendation: boolean; // Признак назначения по рекомендации

  assignmentUserId: number; // ID назначения пользователя

  catalogueAtomVersionId: UUID; // ID версии атома из сессии

  playerSessionId: number; // ID сессии Атома

  finishedAt: DateTimeISO8601 | null; // Дата завершения

  status: AssignmentStatus; // Статус сессии назначения

  sessionUpdatedAt: DateTimeISO8601; // Время последнего обновления сессии

  name: string; // Заголовок атома контента

  source: AtomSource; // Источник атома контента. уточнить, точно ли весь AtomSource

  atomType: AtomType; // Тип назначенного Атома

  trackProgress: {
    finishedStepsCount: number; // Количество пройденных шагов
    totalStepsCount: number; // Общее количество шагов
  }

  constructor(assignmentProps: Partial<IAssignment> = {}) {
    this.catalogueAtomMainId = assignmentProps.catalogueAtomMainId ?? '';
    this.assignmentMainId = assignmentProps.assignmentMainId ?? 0;
    this.startDate = assignmentProps.startDate ?? '';
    this.finishDate = assignmentProps.finishDate ?? null;
    this.selfAssigned = assignmentProps.selfAssigned ?? false;
    this.onRecommendation = assignmentProps.onRecommendation ?? false;
    this.assignmentUserId = assignmentProps.assignmentUserId ?? 0;
    this.catalogueAtomVersionId = assignmentProps.catalogueAtomVersionId ?? '';
    this.playerSessionId = assignmentProps.playerSessionId ?? 0;
    this.finishedAt = assignmentProps.finishedAt ?? null;
    this.status = assignmentProps.status ?? AssignmentStatus.UNKNOWN;
    this.sessionUpdatedAt = assignmentProps.sessionUpdatedAt ?? '';
    this.name = assignmentProps.name ?? '';
    this.source = assignmentProps.source ?? AtomSource.MY_COMPANY;
    this.atomType = assignmentProps.atomType ?? AtomType.UNKNOWN;
    this.trackProgress = assignmentProps.trackProgress ?? {
      finishedStepsCount: 0,
      totalStepsCount: 0,
    };
  }
}
